import { createContext } from "react";

import { AppointmentContextType } from "./types";

const AppointmentContext = createContext<AppointmentContextType>({
  /** Appointment status */
  appointmentStatus: null,
  /** Setter for appointment status */
  setAppointmentStatus: () => { },
});

export default AppointmentContext;
