import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useLazyQuery } from "@apollo/client";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import { Helmet } from "react-helmet";

import { PaymentLocation, PaymentProvider } from "@/autoGeneratedGlobalTypes";
import {
  Button,
  ButtonColorEnum,
  ButtonIconPositionEnum,
  ButtonSizeEnum,
} from "@/components/common/button";
import CashbackDetailsCard from "@/components/common/cashbackDetailsCard";
import { Dropdown, DropdownSizeEnum, DropdownValue } from "@/components/common/dropdown";
import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import PaymentAmountPicker from "@/components/common/PaymentAmountPicker";
import PaymentCashback from "@/components/common/paymentCashback";
import PaymentNotification from "@/components/common/paymentNotification";
import { paymentTexts } from "@/components/constants";
import { FormTypeEnum } from "@/components/layout/modals/types";
import { setAuthParamToURL } from "@/components/layout/modals/utils";
import { calculateCashback, calculateCashbackVariables } from "@/components/profile/paymentForm/graphql/__generated__/calculateCashback";
import { CALCULATE_CASHBACK } from "@/components/profile/paymentForm/graphql/CALCULATE_CASHBACK";
import { CLOUD_PAYMENT_CARD_VALUE, CLOUD_PAYMENT_FOREIGN_CARD_VALUE, MAX_DIGITS_AMOUNT, MIN_SESSION_DURATION, PAYMENT_INITIAL_AMOUNT, ROBOKASSA_PAYMENT_CARD_VALUE, ROBOKASSA_SBP_PAYMENT_CARD_VALUE } from "@/constants";
import { UserContextType } from "@/contexts/User/types";
import UserContext from "@/contexts/User/UserContext";
import { usePaymentForm } from "@/hooks/usePaymentForm";
import { pollingGetPaymentState_getPayment } from "@/hooks/usePaymentPolling/graphql/__generated__/pollingGetPaymentState";
import { usePayWithSavedCard } from "@/hooks/usePayWithSavedCard";
import { getDeviceType, isBrowser } from "@/utils/env";
import { currencyToString } from "@/utils/globalTypesUtils";
import { getAvailableMinutes } from "@/utils/moneyUtils";
import { priceToString } from "@/utils/numberUtils";
import { cleanupFromTags, declenateWord } from "@/utils/stringUtils";

import AppointmentContext from "../context/AppointmentContext";
import { AppointmentContextType } from "../context/types";
import { AppointmentStatusEnum } from "../types";
import { areMoneyAndFreeMinutesEnoughForCall } from "../utils";

import { PaymentProps } from "./types";
import {
  cardToJsx, getMinimumTopUpAmount,
  getPaymentDescription, isAmountValid,
} from "./utils";

import "./styles.scss";

const PaymentForm = ({
  sessionType,
  userCards,
  balance,
  freeMinutesCount,
  callRate,
  expertId,
  isTrialAllowed,
  paymentFormData,
}: PaymentProps) => {
  const {
    appointmentStatus, setAppointmentStatus,
  } = useContext<AppointmentContextType>(AppointmentContext);
  const { isUserLoggedIn, setCurrentPaymentData } = useContext<UserContextType>(UserContext);

  const [isBackgroundEmpty, setIsBackgroundEmpty] = useState<boolean>(false);
  const [isAmountTouched, setIsAmountTouched] = useState<boolean>(false);
  const [selectedAmount, setSelectedAmount] = useState<number>(
    paymentFormData.recommendedSumm || PAYMENT_INITIAL_AMOUNT,
  );
  const [cards, setCards] = useState<DropdownValue[]>([]);
  const [selectedCard, setSelectedCard] = useState<DropdownValue>(CLOUD_PAYMENT_CARD_VALUE);
  const [rememberCard, setRememberCard] = useState<boolean>(true);
  const redirectPathAfterPayment = useLocation().href;
  const [showCashBackDetailsCard, setShowCashBackDetailsCard] = useState<boolean>(false);

  const isSelectedCloudPayemnt = selectedCard.value === CLOUD_PAYMENT_CARD_VALUE.value;
  const isSelectedCloudForeignPayemnt = selectedCard.value === CLOUD_PAYMENT_FOREIGN_CARD_VALUE.value;
  const isSelectedRobokassaPayemnt = selectedCard.value === ROBOKASSA_PAYMENT_CARD_VALUE.value;
  const isSelectedSBPPayemnt = selectedCard.value === ROBOKASSA_SBP_PAYMENT_CARD_VALUE.value;

  const hasCashBackButtons = useMemo(() =>
    paymentFormData?.buttons?.some((item) =>
      item.cashback?.amount), [paymentFormData.buttons]);

  const [calculateCashbackQuery, { data: calculateCashbackData }] = useLazyQuery<
    calculateCashback, calculateCashbackVariables>(CALCULATE_CASHBACK, { fetchPolicy: "no-cache" });

  useEffect(() => {
    if (selectedAmount) {
      calculateCashbackQuery({ variables: { input: { paymentAmount: selectedAmount } } });
    }
  }, [calculateCashbackQuery, selectedAmount]);

  const paymentSuccessCallback = useCallback(() => {
    // When paying with new card payment provider shows paymen result,
    // so there is no need to do this ourselves as we do with payment by token
    setAppointmentStatus(AppointmentStatusEnum.SessionStart);
    setIsBackgroundEmpty(false);
  }, [setAppointmentStatus]);

  const paymentFailCallback = useCallback(() => {
    // When payingg with new card payment provider shows paymen result,
    // so there is no need to do this ourselves as we do with payment by token
    setAppointmentStatus(
      areMoneyAndFreeMinutesEnoughForCall(
        balance?.amount ?? null,
        callRate,
        freeMinutesCount ?? 0,
        sessionType,
        isTrialAllowed,
      )
        ? AppointmentStatusEnum.StartOrPayChoice
        : AppointmentStatusEnum.Payment,
    );
    setIsBackgroundEmpty(false);
  }, [balance?.amount, callRate, freeMinutesCount, isTrialAllowed, sessionType, setAppointmentStatus]);

  useEffect(() => {
    // результат после оплаты иностранной картой
    if (redirectPathAfterPayment.includes("request=success")) {
      paymentSuccessCallback();
    }

    if (redirectPathAfterPayment.includes("request=fail")) {
      setCurrentPaymentData(null);
      paymentFailCallback();
    }
  }, [paymentFailCallback, paymentSuccessCallback, redirectPathAfterPayment, setCurrentPaymentData]);

  const { openPaymentForm, loadingPaymentNewCard } = usePaymentForm({
    onSuccess: paymentSuccessCallback,
    onFail: paymentFailCallback,
  });

  const paymentWithSavedCardSuccessCallback = useCallback(
    (resultData: pollingGetPaymentState_getPayment) => {
      // todo for phone calls: maybe do this in paymentWithSavedCardCompleteCallback
      if (resultData) {
        window.history.replaceState({}, "", `${window.location.pathname}${window.location.search}`);
        setAppointmentStatus(AppointmentStatusEnum.PaymentSuccess);
      }
    },
    [setAppointmentStatus],
  );

  const paymentWithSavedCardFailCallback = useCallback(
    (resultData: pollingGetPaymentState_getPayment) => {
      if (resultData) {
        setAppointmentStatus(AppointmentStatusEnum.PaymentFailed);
      }
    },
    [setAppointmentStatus],
  );

  const { payWithSavedCard, loading: payWithSavedCardLoading } = usePayWithSavedCard({
    onSuccess: paymentWithSavedCardSuccessCallback,
    onFail: paymentWithSavedCardFailCallback,
  });

  useEffect(() => {
    const joinRobokassaPaymentFlag = process.env.GATSBY_ADD_ROBOKASSA_PAYMENT === "true";
    const joinRobokassaSbpPaymentFlag = process.env.GATSBY_ADD_ROBOKASSA_SBP_PAYMENT === "true";

    if (userCards) {
      const convertedUserCards: DropdownValue[] = userCards?.map((c) =>
        cardToJsx(c)) ?? [];

      convertedUserCards.push(CLOUD_PAYMENT_CARD_VALUE);

      if (joinRobokassaSbpPaymentFlag) {
        convertedUserCards.push(ROBOKASSA_SBP_PAYMENT_CARD_VALUE);
      }

      if (joinRobokassaPaymentFlag) {
        convertedUserCards.push(ROBOKASSA_PAYMENT_CARD_VALUE);
      }

      setCards(convertedUserCards);
      const defaultCard = userCards.find((card) =>
        card.isDefault);
      if (defaultCard) {
        setSelectedCard(cardToJsx(defaultCard));
      } else {
        setSelectedCard(CLOUD_PAYMENT_CARD_VALUE);
      }
    }
  }, [userCards]);

  if (!isUserLoggedIn) {
    if (isBrowser()) {
      navigate("/");
    }
  }

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numberValue = parseInt(e.target.value, 10);
    // user may enter zeros before the value, and we preserve them,
    // but we make sure the value is valid
    setSelectedAmount((oldValue) =>
      (numberValue.toString().length <= MAX_DIGITS_AMOUNT ? numberValue : oldValue));
  };

  const handleCardChange = (value: DropdownValue) => {
    setSelectedCard(value);
    if (value.value === CLOUD_PAYMENT_CARD_VALUE.value) {
      setTimeout(() => {
        // todo: make scrolling to bottom
      }, 1000);
    }
  };

  const handleBulletClick = (amount: number) => {
    setSelectedAmount(amount);
  };

  const handleStartSessionClick = () => {
    setAppointmentStatus(AppointmentStatusEnum.SessionStart);
  };

  const handlePayClick = () => {
    switch (true) {
      case isSelectedCloudPayemnt || isSelectedCloudForeignPayemnt:
        setIsBackgroundEmpty(true);
        openPaymentForm(
          selectedAmount,
          rememberCard,
          PaymentProvider.CloudPayments,
          "",
          PaymentLocation.appointment,
          expertId,
        );
        break;
      case isSelectedRobokassaPayemnt:
        openPaymentForm(
          selectedAmount,
          false, // На первом этапе разработки карту не сохраняем!
          PaymentProvider.RobokassaPayments,
          redirectPathAfterPayment,
          PaymentLocation.appointment,
          expertId,
        );
        break;
      case isSelectedSBPPayemnt:
        openPaymentForm(
          selectedAmount,
          false,
          PaymentProvider.Other,
          redirectPathAfterPayment,
          PaymentLocation.appointment,
          expertId,
        );
        break;
      default:
        payWithSavedCard(selectedAmount, Number(selectedCard.value), PaymentLocation.appointment, expertId);
    }
  };

  const toggleShowCashBackDetailsCard = () => {
    const deviceType = getDeviceType();

    if (deviceType === "isMobile") {
      setShowCashBackDetailsCard((prevState) =>
        !prevState);
    } else {
      setAuthParamToURL(window?.location, FormTypeEnum.CashBackInformation);
    }
  };

  const availableMinutes = getAvailableMinutes(balance.amount, callRate);
  const isPaymentFormShown = appointmentStatus === AppointmentStatusEnum.Payment;

  const calculateBonus = calculateCashbackData?.calculateCashback?.bonus;
  const calculateProfit = calculateCashbackData?.calculateCashback?.profit;
  const showCheckboxForSaveCard = isSelectedCloudPayemnt || isSelectedCloudForeignPayemnt;

  return (
    <>
      <Helmet>
        <body className="footer--short header--short" />
      </Helmet>
      <div style={{ display: isBackgroundEmpty ? "none" : "block" }}>
        <div className="payment__container">
          <div className="payment">
            {isPaymentFormShown && <h2 className="payment__header">Пополните баланс</h2>}
            <div className="payment__description">
              {getPaymentDescription(balance.amount, callRate, freeMinutesCount, isTrialAllowed)}
            </div>

            <div className="payment__card">
              <div>
                <div className="payment__card-label">Ваш баланс</div>
                <h4 className="payment__balance">
                  <span
                    className="payment__amount"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: cleanupFromTags(priceToString(balance.amount).replace(" ", "&nbsp;")),
                    }}
                  />
                  &nbsp;
                  <span className="payment__currency">{currencyToString(balance.currency)}</span>
                </h4>
              </div>
              <div>
                {isPaymentFormShown && !!availableMinutes && (
                  <div className="payment__enough-for">
                    Хватит на
                    {" "}
                    {availableMinutes}
                    {" "}
                    {declenateWord(availableMinutes, ["минуту", "минуты", "минут"])}
                  </div>
                )}
                {!isPaymentFormShown && (
                  <Button
                    text="Пополнить"
                    subtext={selectedAmount ? `${priceToString(selectedAmount)} ₽` : ""}
                    size={ButtonSizeEnum.XSmall}
                    color={ButtonColorEnum.Dark}
                    onClick={() => {
                      setAppointmentStatus(AppointmentStatusEnum.Payment);
                    }}
                  />
                )}
              </div>
            </div>

            {isPaymentFormShown && (
              <form
                className="payment__form"
                onSubmit={(e) =>
                  e.preventDefault()}
              >
                <div className="payment__field-groups">
                  <div className="payment__field-group">
                    <div className="payment__label">
                      <label htmlFor="amount">Сумма пополнения</label>
                    </div>
                    <input
                      id="amount"
                      name="amount"
                      type="number"
                      pattern="\d*"
                      inputMode="numeric"
                      value={Number.isNaN(selectedAmount) ? "" : selectedAmount}
                      onChange={handleAmountChange}
                      onBlur={() =>
                        setIsAmountTouched(true)}
                      className={`payment__input amount${isAmountTouched
                        && !isAmountValid(
                          selectedAmount,
                          balance.amount,
                          callRate,
                          freeMinutesCount,
                        )
                        ? " error"
                        : ""
                      }`}
                      aria-invalid={
                        isAmountTouched
                          && !isAmountValid(
                            selectedAmount,
                            balance.amount,
                            callRate,
                            freeMinutesCount,
                          )
                          ? "true"
                          : "false"
                      }
                    />
                    {isAmountValid(selectedAmount, balance.amount, callRate, freeMinutesCount) && (
                      <div className="payment__minutes-text" role="alert">
                        {`Хватит на ${getAvailableMinutes(selectedAmount, callRate)}
                        ${declenateWord(getAvailableMinutes(selectedAmount, callRate), ["минуту", "минуты", "минут"])}
                        разговора`}
                      </div>
                    )}
                    {isAmountTouched
                      && !isAmountValid(
                        selectedAmount,
                        balance.amount,
                        callRate,
                        freeMinutesCount,
                      ) && (
                        <div className="payment__error" role="alert">
                          {`Минимальная сумма пополнения — ${getMinimumTopUpAmount(isTrialAllowed, freeMinutesCount, callRate, balance.amount)} ₽`}
                        </div>
                    )}
                  </div>
                  <PaymentAmountPicker
                    handleBulletClick={handleBulletClick}
                    dataFormButtons={paymentFormData}
                  />
                  <div className={hasCashBackButtons ? "payment__notification" : ""}>
                    <PaymentNotification />
                  </div>
                  <div className="payment__field-group">
                    <div className="payment__label">
                      <label htmlFor="card">Способ пополнения</label>
                    </div>
                    <Dropdown
                      id="card"
                      name="card"
                      value={selectedCard}
                      options={cards}
                      onChange={handleCardChange}
                      size={DropdownSizeEnum.Middle}
                      className="payment__input"
                    />
                  </div>
                  <div className="payment__sticky">
                    {showCashBackDetailsCard
                      && (
                        <CashbackDetailsCard
                          onClick={toggleShowCashBackDetailsCard}
                        />
                      )}

                    {calculateBonus && !showCashBackDetailsCard
                      && (
                        <>
                          <PaymentCashback
                            bonusAmount={calculateBonus.bonusAmount}
                            bonusFormula={calculateBonus.bonusFormula}
                            profitAmount={calculateProfit?.amount}
                            profitType={calculateProfit?.type}
                            toggleShowCashBackDetailsCard={toggleShowCashBackDetailsCard}
                          />
                        </>
                      )}

                    {!showCashBackDetailsCard && (
                      <Button
                        text="Пополнить"
                        subtext={selectedAmount ? `на ${priceToString(selectedAmount)} ₽` : ""}
                        size={ButtonSizeEnum.Large}
                        color={ButtonColorEnum.Dark}
                        className="payment__submit"
                        isLoading={payWithSavedCardLoading || loadingPaymentNewCard}
                        disabled={
                          !isAmountValid(
                            selectedAmount,
                            balance.amount,
                            callRate,
                            freeMinutesCount,
                          )
                          || getAvailableMinutes(balance.amount, callRate, true)
                          + getAvailableMinutes(selectedAmount, callRate, true)
                          < (MIN_SESSION_DURATION - freeMinutesCount)
                        }
                        onClick={handlePayClick}
                      />
                    )}
                  </div>
                  {showCheckboxForSaveCard && (
                    <div className="payment__remember-block">
                      <div className="payment__checkbox-group">
                        <input
                          type="checkbox"
                          checked={rememberCard}
                          onChange={() =>
                            setRememberCard((prevVal) =>
                              !prevVal)}
                        />
                        <span className="payment__checkbox-label">{paymentTexts.rememberText}</span>
                      </div>
                      <div className="payment__message">{paymentTexts.message}</div>
                      <div className="payment__message">
                        <Icon type={IconTypeEnum.Shield} size={IconSizeEnum.Size28} />
                        {paymentTexts.protectionInfo}
                      </div>
                    </div>
                  )}
                </div>
              </form>
            )}
            {!isPaymentFormShown && (
              <div className="payment__sticky">
                <Button
                  text="Начать консультацию"
                  size={ButtonSizeEnum.Large}
                  color={ButtonColorEnum.Dark}
                  icon={IconTypeEnum.Right}
                  iconPosition={ButtonIconPositionEnum.Right}
                  className="payment__submit"
                  onClick={handleStartSessionClick}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentForm;
