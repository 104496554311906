import React, { useMemo, useState } from "react";

import { AppointmentStatusEnum } from "../types";

import AppointmentContext from "./AppointmentContext";
import { AppointmentContextProps } from "./types";

const AppointmentContextProvider = ({ children }: AppointmentContextProps) => {
  const [appointmentStatus, setAppointmentStatus] = useState<AppointmentStatusEnum>();

  const AppointmentContextValue = useMemo(
    () =>
      ({
        appointmentStatus,
        setAppointmentStatus,
      }),
    [appointmentStatus],
  );

  return (
    <AppointmentContext.Provider value={AppointmentContextValue}>
      {children}
    </AppointmentContext.Provider>
  );
};

export default AppointmentContextProvider;
