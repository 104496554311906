import React, { useContext, useEffect, useState } from "react";

import { navigate } from "gatsby";

import { CardType, PaymentError, PaymentState } from "@/autoGeneratedGlobalTypes";
import {
  Button,
  ButtonColorEnum,
  ButtonIconPositionEnum,
  ButtonSizeEnum,
} from "@/components/common/button";
import Confetti from "@/components/common/confetti";
import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import Support from "@/components/common/support";
import { UserContextType } from "@/contexts/User/types";
import UserContext from "@/contexts/User/UserContext";
import { convertToDate, convertToTime } from "@/utils/dateUtils";
import { isBrowser } from "@/utils/env";
import { cardTypeToString, currencyToString } from "@/utils/globalTypesUtils";
import { priceToString } from "@/utils/numberUtils";

import { PaymentResultProps } from "./types";

import "./styles.scss";

const PaymentResult = ({ handleButtonClick }: PaymentResultProps) => {
  const { isUserLoggedIn, currentPaymentData, setCurrentPaymentData } = useContext<UserContextType>(UserContext);
  const [areDetailsOpened, setAreDetailsOpened] = useState<boolean>(false);

  useEffect(() => {
    if (!isUserLoggedIn && isBrowser()) {
      navigate("/");
    }

    return () =>
      setCurrentPaymentData(null);
  }, [isUserLoggedIn, setCurrentPaymentData]);

  const handleDetailsClick = () => {
    setAreDetailsOpened((oldVal) =>
      !oldVal);
  };

  const {
    amount: {
      amount,
      currency,
    },
    errorValue,
    datetime,
    card,
  } = currentPaymentData ?? {
    amount: {},
    card: {
      type: undefined,
      number: undefined,
    },
  };

  const isSuccess = currentPaymentData?.state === PaymentState.Success;
  const isFailure = currentPaymentData?.state === PaymentState.Failed;
  const paymentId = currentPaymentData?.id;
  const showSuccessForm = isSuccess && currency;

  return (
    <>
      {showSuccessForm && <Confetti />}
      <div className="payment-result__container">
        <div className="payment-result">
          <div className="payment-result__middle">
            {showSuccessForm && (
            <>
              <div className="payment-result__success-circle">
                <Icon type={IconTypeEnum.Tick} size={IconSizeEnum.Size24} />
              </div>
              <h3 className="payment-result__success-header">
                Ваш баланс пополнен
                {" "}
                {amount ? `на ${priceToString(amount)} ${currencyToString(currency)}\n` : ""}
              </h3>
            </>
            )}
            {isFailure && currency && (
            <>
              <div className="payment-result__failure-circle">
                <Icon type={IconTypeEnum.Exclamation} size={IconSizeEnum.Size24} />
              </div>
              <h3 className="payment-result__failure-header">
                Платёж
                {" "}
                {amount ? `на ${priceToString(amount)} ${currencyToString(currency)}\n` : ""}
                отклонён
              </h3>

              {errorValue === PaymentError.InsufficientFundsOnBalance
                && (
                  <div className="payment-result__text">
                    Недостаточно средств. Пополните карту
                    <br />
                    или воспользуйтесь другой.
                  </div>
                )}

              {errorValue === PaymentError.CommonError && (
              <div className="payment-result__text">Произошла ошибка</div>
              )}
            </>
            )}
            <div
              className={areDetailsOpened
                ? "payment-result__details is-opened"
                : "payment-result__details"}
              onClick={handleDetailsClick}
            >
              <span>Детали</span>
              {areDetailsOpened && (
              <div className="payment-result__table">
                {card && (
                <div className="payment-result__row">
                  <div className="payment-result__column">
                    <label>
                      <Icon type={IconTypeEnum.Card} size={IconSizeEnum.Size20} />
                      <span>Карта</span>
                    </label>
                  </div>
                  <div className="payment-result__column">
                    <span>
                      {cardTypeToString(card.type ?? CardType.Unknown)}
                      {" "}
                      •
                      {card.number}
                    </span>
                  </div>
                </div>
                )}
                <div className="payment-result__row">
                  <div className="payment-result__column">
                    <label>
                      <Icon type={IconTypeEnum.Number} size={IconSizeEnum.Size20} />
                      <span>Платёж</span>
                    </label>
                  </div>
                  <div className="payment-result__column">
                    <span>{paymentId}</span>
                  </div>
                </div>
                {datetime && (
                <div className="payment-result__row">
                  <div className="payment-result__column">
                    <label>
                      <Icon type={IconTypeEnum.Time} size={IconSizeEnum.Size20} />
                      <span>Дата</span>
                    </label>
                  </div>
                  <div className="payment-result__column">
                    <span>
                      {convertToTime(datetime)}
                      {" "}
                      {convertToDate(datetime)}
                    </span>
                  </div>
                </div>
                )}
              </div>
              )}
            </div>
          </div>
          <div className="payment-result__bottom">
            <Button
              className="payment-result__button"
              text={isFailure ? "Вернуться к оплате" : "Начать консультацию"}
              icon={isFailure ? undefined : IconTypeEnum.Right}
              iconPosition={isFailure ? undefined : ButtonIconPositionEnum.Right}
              size={ButtonSizeEnum.Large}
              color={ButtonColorEnum.Dark}
              onClick={handleButtonClick}
            />
            {isFailure && (
            <Support isShort />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentResult;
