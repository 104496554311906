import { SessionType } from "@/autoGeneratedGlobalTypes";
import { MIN_SESSION_DURATION } from "@/constants";
import { isUserEligibleForImmediateCall } from "@/utils/commonUtils";
import { getAvailableMinutes } from "@/utils/moneyUtils";
import { IconTypeEnum } from "@components/common/icon";

import { getAppointmentPageData_getExpert_rates } from "./graphql/__generated__/getAppointmentPageData";
import { AppointmentStatusEnum } from "./types";

/**
 * Returns flag showing if session is ready to be created
 * @param appointmentStatus
 * @returns
 */
export const isSessionReadyToBeCreated = (appointmentStatus: AppointmentStatusEnum | null) =>
  appointmentStatus
  && [AppointmentStatusEnum.PaymentSkipped, AppointmentStatusEnum.SessionStart]
    .includes(appointmentStatus);

export const areMoneyAndFreeMinutesEnoughForCall = (
  balanceAmount: number | null,
  callRate: number,
  freeMinutesCount: number,
  sessionType: SessionType,
  isTrialAllowed: boolean,
) => {
  if (!balanceAmount || balanceAmount <= 0) {
    return false;
  }

  const countFreeMinutesFlag = (sessionType === SessionType.VIDEO_CALL && isTrialAllowed)
  || (sessionType === SessionType.CHAT && isTrialAllowed);

  return (getAvailableMinutes(balanceAmount, callRate) + (countFreeMinutesFlag ? freeMinutesCount : 0))
    >= MIN_SESSION_DURATION;
};

/**
 * Calculates initial appiontment status
 * @param freeMinutesCount
 * @param isSuccess
 * @returns
 */
export const getInitialAppointmentStatus = (
  sessionType: SessionType,
  balanceAmount: number | null,
  callRate: number,
  freeMinutesCount: number,
  isTrialAllowed: boolean,
  isSuccess?: boolean,
  isFailure?: boolean,
): AppointmentStatusEnum => {
  if (isUserEligibleForImmediateCall(sessionType, freeMinutesCount, isTrialAllowed)) {
    return AppointmentStatusEnum.PaymentSkipped;
  }
  if (isSuccess) {
    return AppointmentStatusEnum.PaymentSuccess;
  }
  if (isFailure) {
    return AppointmentStatusEnum.PaymentFailed;
  }
  return areMoneyAndFreeMinutesEnoughForCall(
    balanceAmount,
    callRate,
    freeMinutesCount,
    sessionType,
    isTrialAllowed,
  )
    ? AppointmentStatusEnum.StartOrPayChoice
    : AppointmentStatusEnum.Payment;
};

export const getSessionTypeIcon = (sessionType: SessionType) => {
  switch (sessionType) {
    case SessionType.PHONE_CALL: return IconTypeEnum.Call;
    case SessionType.CHAT: return IconTypeEnum.Chat;
    default: return IconTypeEnum.Video;
  }
};

/**
 * @param rates - Тарифы эксперта для разных типов сеансов.
 * @param currentSessionType - Тип текущего сеанса.
 * @returns Тариф, соответствующий указанному типу сеанса.
 */

export const getSessionRate = (rates: getAppointmentPageData_getExpert_rates, currentSessionType: SessionType) => {
  switch (currentSessionType) {
    case SessionType.PHONE_CALL: return rates.phoneCall;
    case SessionType.CHAT: return rates.chat;
    default: return rates.videoCall;
  }
};
